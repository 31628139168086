import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'App',
    children: [
      {
        path: '/',
        name: 'Home',
        component: () =>
          import(
            /* webpackChunkName: "menu",webpackPrefetch:true */ '../views/menu/HomeView.vue'
          )
      },
      {
        path: '/menu/hotel',
        name: 'HotelCasinoView',
        component: () =>
          import(
            /* webpackChunkName: "menu",webpackPrefetch:true */ '../views/menu/HotelCasinoView.vue'
          )
      },
      {
        path: '/menu/Visa',
        name: 'VisaView',
        component: () =>
          import(
            /* webpackChunkName: "menu",webpackPrefetch:true */ '../views/menu/VisaView.vue'
          )
      },
      {
        path: '/menu/notices',
        name: 'NoticesView',
        component: () =>
          import(
            /* webpackChunkName: "menu",webpackPrefetch:true */ '../views/menu/NoticesView.vue'
          )
      },
      {
        path: '/menu/event',
        name: 'EventView',
        component: () =>
          import(
            /* webpackChunkName: "menu",webpackPrefetch:true */ '../views/menu/EventView.vue'
          )
      },
      {
        path: '/menu/TableView',
        name: 'TableView',
        component: () =>
          import(
            /* webpackChunkName: "menu",webpackPrefetch:true */ '../views/menu/TableView'
          )
      },
      {
        path: '/PHPageView',
        name: 'PHPageView',
        component: () =>
          import(
            /* webpackChunkName: "menu",webpackPrefetch:true */ '../views/menu/PHPageView'
          )
      },
      {
        path: '/Notices/1',
        name: 'Notices01',
        component: () =>
          import(
            /* webpackChunkName: "menu",webpackPrefetch:true */ '../components/TableDetailPage/Notices01'
          )
      },
      {
        path: '/Notices/2',
        name: 'Notices02',
        component: () =>
          import(
            /* webpackChunkName: "menu",webpackPrefetch:true */ '../components/TableDetailPage/Notices02'
          )
      },
      {
        path: '/Notices/3',
        name: 'Notices03',
        component: () =>
          import(
            /* webpackChunkName: "menu",webpackPrefetch:true */ '../components/TableDetailPage/Notices03'
          )
      },
      {
        path: '/Notices/4',
        name: 'Notices04',
        component: () =>
          import(
            /* webpackChunkName: "menu",webpackPrefetch:true */ '../components/TableDetailPage/Notices04'
          )
      },
      {
        path: '/PHPage/1',
        name: 'PH01',
        component: () =>
          import(
            /* webpackChunkName: "menu",webpackPrefetch:true */ '../components/TableDetailPage/PH01'
          )
      },
      {
        path: '/PHPage/2',
        name: 'PH02',
        component: () =>
          import(
            /* webpackChunkName: "menu",webpackPrefetch:true */ '../components/TableDetailPage/PH02'
          )
      },
      {
        path: '/PHPage/3',
        name: 'PH03',
        component: () =>
          import(
            /* webpackChunkName: "menu",webpackPrefetch:true */ '../components/TableDetailPage/PH03'
          )
      },
      {
        path: '/PHPage/4',
        name: 'PH04',
        component: () =>
          import(
            /* webpackChunkName: "menu",webpackPrefetch:true */ '../components/TableDetailPage/PH04'
          )
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
