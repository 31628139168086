<template>
  <head>
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, user-scalable=no,
    maximum-scale=1.0, minimum-scale=1.0"
    />
  </head>
  <div>
    <div id="app">
      <!-- 추가된 헤더 컴포넌트 사용 -->
      <Header />
      <Slide />
      <div class="main-board-data-wrapper m-b-none">
        <router-view></router-view>
      </div>
      <!-- 아래부터 실시간 입/출금 제목 -->
      <!-- 실시간 입/출금 테이블 종료 -->
      <div class="main-board-data-wrapper m-b-none">
        <div class="middle-row mt-8"></div>
      </div>
      <!-- 추가된 풋터 컴포넌트 사용 -->
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from './components/layout/Header'

import Slide from './components/Swiper/Slide.vue'

import Footer from './components/layout/Footer'

export default {
  name: 'App',
  components: {
    Header,
    Slide,

    Footer
  },
  data() {
    return {}
  },
  mounted() {}
}
</script>

<style scoped>
html,
body {
  padding: 0;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0;
  width: media-width;
}
.main-board-data-wrapper {
  background-color: black;
}
/* div {
  -webkit-touch-callout: none;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}
body {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-use-select: none;
  user-select: none;
}
.draggable {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-use-select: text;
  user-select: text;
}
img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -user-drag: none;
} */
</style>
