<template>
  <head>
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, user-scalable=no,
    maximum-scale=1.0, minimum-scale=1.0"
    />
  </head>
  <header class="header-pc">
    <div class="header-wrapper">
      <nav class="mainNav">
        <div class="NavCase1">
          <div class="header-logo">
            <a href="/" class="router-link-logo">
              <img
                class="set"
                src="../../assets/images/PHMainLogo01.png"
                alt="나인에이전시 : 필리핀 카지노 아바타,스피드,라이브 게임 전문"
                width="199.255813953"
                style="height: auto"
              />
            </a>
          </div>
          <router-link to="/" class="color">홈</router-link>
          <router-link to="/menu/Visa" class="router-padding color"
            >초이스나인</router-link
          >
          <router-link to="/menu/hotel" class="router-padding dropbtn color"
            >카지노호텔</router-link
          >
          <router-link to="/menu/notices" class="router-padding color"
            >커뮤니티</router-link
          >
          <router-link to="/menu/event" class="event-padding color"
            >이용방법</router-link
          >
        </div>
        <div class="NavCase2 mainNav">
          <div class="header-logo">
            <a href="/" class="router-link-logo">
              <img
                class="set"
                src="../../assets/images/PHMainLogo01.png"
                alt="나인에이전시 : 필리핀 카지노 아바타,스피드,라이브 게임 전문"
                width="199.255813953"
              />
            </a>
          </div>
          <div>
            <router-link to="/" class="color">홈</router-link>
            <router-link to="/menu/Visa" class="router-padding color"
              >초이스나인</router-link
            >
            <router-link to="/menu/hotel" class="router-padding dropbtn color"
              >카지노호텔</router-link
            >
            <router-link to="/menu/notices" class="router-padding color"
              >커뮤니티</router-link
            >
            <router-link to="/menu/event" class="event-padding color"
              >이용방법</router-link
            >
          </div>
        </div>
      </nav>
    </div>
  </header>
</template>
<script>
export default {
  name: 'Header',
  components: {},
  data() {
    return {
      // isLogin: this.$store.state.login
    }
  },
  setup() {},
  created() {},
  mounted() {},
  unmounted() {},
  methods: {
    testLogin: function () {
      this.$store.dispatch('testLoginAction')
    }
  }
}
</script>
<style scoped>
.router-link-logo img {
  max-height: 100px;
  max-width: 160px;
}
.header-logo img {
  margin-top: -10px;
  vertical-align: middle;
  /* margin-left: -220px; */
}
nav a.router-link-exact-active {
  color: #fbe194;
  font-weight: 700;
  font-family: AppleSDGothicNeoM;
  border-bottom: #fbe194 solid 1px;
}
a {
  text-decoration-line: none;
  text-decoration: unset;
}
button {
  appearance: auto;
  writing-mode: horizontal-tb !important;
  margin: 0;
  text-indent: 0px;
}
header {
  backdrop-filter: blur(12px);
  color: #fff;
  height: 70px;
  position: fixed;
  z-index: 10;
  width: 100%;
}
.header-logo {
  display: inline-block;
  font-size: 36px;
  font-weight: 700;
  padding: 20px 0px 0px 0px;
}
/** 배너 css */

a {
  text-decoration: unset;
}
.nav-icon-live {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  display: block;
  height: 17px;
  position: absolute;
  right: 945px;
  top: 23px;
  width: 31px;
}
.nav-icon-slot {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  display: block;
  height: 17px;
  position: absolute;
  right: 664px;
  top: 23px;
  width: 31px;
}
.nav-icon-event {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  display: block;
  height: 17px;
  position: absolute;
  right: 414px;
  top: 23px;
  width: 31px;
}

.dropbtn {
  /* background-color: rgb(207, 152, 212); */
  color: white;
  /* padding: 16px;
  font-size: 16px; */
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* .dropdown-content a:hover {
  background-color: #ddd;
} */

.dropdown:hover .dropdown-content {
  display: block;
}
.color {
  color: #d0d0d0;
}
/* .color :hover {
  color: #fbe194;
} */
/* .color :hover {
  cursor: pointer;
  opacity: 0.94;
  color: #fbe194;
} */
.router-padding {
  margin-left: 10px;
  margin-right: 10px;
}
a {
  text-decoration-line: none;
  text-decoration: unset;
  padding: 0px;
  margin: 0px;
}
/* .hoverborder {
  border-bottom: #fbe194 solid 2px;
  color: #fbe194;
} */
.mainNav {
  background-color: rgb(0, 0, 0);
  display: inline-block;
  position: relative;
  top: -8px;
  width: 100%;
  /* min-width: 820px; */
  margin: 0 auto;
}
.NavCase2 {
  display: none;
}
/** 이건 width 500px 넘어 가기전 */
nav a {
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  padding: 0 auto;
}
@media screen and (min-width: 500px) {
  nav a {
    padding: 5px 18px;
    font-size: 18px;
    font-weight: bold;
    color: white;
  }
}
@media screen and (max-width: 530px) {
  nav a {
    padding: 5px 18px;
    font-size: 15px;
    font-weight: bold;
    color: white;
  }
}
@media screen and (min-width: 700px) {
  nav a {
    padding: 5px 18px;
    font-size: 18px;
    font-weight: bold;
    color: white;
  }
}
@media screen and (max-width: 800px) {
  .mainNav {
    background-color: rgb(0, 0, 0);
    /* min-width: 100%;
    display: grid */
  }
  .NavCase2 {
    display: block;
  }
  .NavCase1 {
    display: none;
  }
  .header {
    width: 1200px;
  }
}
@media screen and (max-width: 970px) {
  .router-padding {
    margin-left: 5px;
    margin-right: 5px;
  }
}
@media screen and (min-width: 1200px) {
  /* .set {
    width: 300px;
    height: 100px;
  } */
}
@media screen and (min-width: 1400px) {
  nav a {
    padding: 5px 28px;
    font-size: 18px;
    font-weight: bold;
    color: white;
  }
}
nav_btn2 {
  color: #eb0707;
  display: flex;
  font-size: 18px;
  justify-content: right;
  position: absolute;
  right: 0;
  top: 20px;
}
@media screen and (max-width: 475px) {
  nav a {
    padding: 5px 18px;
    font-size: 14px;
    font-weight: bold;
    color: white;
  }
}
@media screen and (max-width: 465px) {
  nav a {
    padding: 5px 14px;
    font-size: 13px;
    font-weight: bold;
    color: white;
  }
}
@media screen and (max-width: 415px) {
  nav a {
    padding: 5px 10px;
    font-size: 13px;
    font-weight: bold;
    color: white;
  }
}
@media screen and (max-width: 365px) {
  nav a {
    padding: 5px 6px;
    font-size: 12px;
    font-weight: bold;
    color: white;
  }
}
@media screen and (max-width: 935px) {
  nav a {
    padding: 5px 15px;
    font-size: 16px;
    font-weight: bold;
    color: white;
  }
}
@media screen and (max-width: 850px) {
  nav a {
    padding: 5px 12px;
    font-size: 16px;
    font-weight: bold;
    color: white;
  }
}
@media screen and (max-width: 440px) {
  nav a {
    padding: 5px 10px;
    font-size: 13px;
    font-weight: bold;
    color: white;
  }
}
@media screen and (max-width: 360px) {
  nav a {
    padding: 2px 7px;
    font-size: 12px;
    font-weight: bold;
    color: white;
  }
}
</style>
