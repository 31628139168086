<template>
  <div class="footer-text" style="background-color: black">
    <h4>
      필리핀 카지노, 필리핀 마닐라, 필리핀 골프, VIP에이전시 초이스나인<br />
      <a :href="'https://open.kakao.com/o/sxDn6ite'" style="color: #ffe90a">
        카카오톡 오픈채팅 이동
      </a>
    </h4>
    <div class="Service_content_Wrapper">
      <div class="Service_content">
        <div class="Service_content_image">
          <a href="tel:001-63-927-761-0465">
            <img src="../../assets/images/cellphone.jpg" />
          </a>
        </div>
        <div class="Content_Out"></div>
        <div class="serviceText">
          <h3>초이스나인 고객센터</h3>
          <a href="tel:001-63-927-761-0465" style="color: rgb(127, 218, 91)"
            >001-63-927-761-0465</a
          >
        </div>
        <div class="Content_Out"></div>
      </div>
      <div class="Service_content">
        <div class="Service_content_image">
          <a href="https://open.kakao.com/o/sxDn6ite">
            <img src="../../assets/images/kakaotalk.jpg" />
          </a>
        </div>
        <div class="Content_Out"></div>
        <div class="serviceText">
          <h3>카카오톡</h3>
          <a style="color: slategray">choice9</a>
        </div>
        <div class="Content_Out"></div>
      </div>
      <div class="Service_content">
        <div class="Service_content_image">
          <a href="https://t.me/choice9">
            <img src="../../assets/images/telegram.jpg" />
          </a>
        </div>
        <div class="Content_Out"></div>
        <div class="serviceText">
          <h3>텔레그램</h3>
          <ha style="color: slategray">choice9</ha>
        </div>
        <div class="Content_Out"></div>
      </div>
    </div>
    <div class="footer-text"></div>
    <div style="background-color: black">
      <p>10년 이상의 노련함으로 보다 안전한 VIP 서비스</p>
      <p>24시간 고객센터 운영</p>
      <p>Copyright@2014 CHOICE9.A11 Rights Reserved.</p>
      <p>
        초이스나인에서 제공하는 모든 컨텐츠의 저작권은 초이스나인에 있습니다.
      </p>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.footer-text {
  border-top: 1px solid #2e2e2e;
  color: #ababab;
  font-size: 12px;
  padding: 30px 0;
  position: absolute;
  text-align: center;
  width: 100%;
  display: block;
}
.Service_content_image {
  width: 100px;
  height: 100px;
  border-radius: 70%;
  overflow: hidden;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: rgb(255, 255, 255);
}
.Service_content_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.Service_content_Wrapper {
  display: flex;
  justify-content: center;
  padding-bottom: 30px;
}
@media screen and (max-width: 600px) {
  .Service_content_Wrapper {
    display: grid;
  }
}
img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  -user-drag: none;
}
</style>
