import { createStore } from 'vuex'

export default createStore({
  state: {
    login: false
  },
  getters: {
  },
  mutations: {
    testLogin: function (state) {
      state.login = !state.login
    }
  },
  actions: {
    testLoginAction: function (context) {
      context.commit('testLogin')
    }
  },
  modules: {
  }
})

// import Vue from 'vue'
// import Vuex from 'vuex'

// Vue.use(Vuex)

// export default new Vuex.Store({
//   state: {
//     data() {
//       return {
//         Login: false
//       }
//     },
//     methods: {
//       testLogin: function () {
//         this.Login = !this.Login
//       }
//     }
//   },
//   mutations: {},
//   actions: {},
//   modules: {}
// })
