<template>
  <swiper
    :navigation="true"
    :autoplay="true"
    :modules="modules"
    class="mySwiper"
  >
    <swiper-slide>
      <img class="slide-img" src="../../assets/images/banner1.jpg" />
    </swiper-slide>
    <swiper-slide>
      <img class="slide-img" src="../../assets/images/banner2.jpg" />
    </swiper-slide>
    <swiper-slide>
      <img class="slide-img" src="../../assets/images/banner3.jpg" />
    </swiper-slide>
  </swiper>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue'

// Import Swiper styles
import 'swiper/css'

import 'swiper/css/navigation'

// import required modules
import { Navigation, Autoplay } from 'swiper'

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  setup() {
    return {
      modules: [Navigation, Autoplay]
    }
  }
}
</script>
<style scoped>
.slide-img {
  margin-bottom: -6px;
  margin-top: -10px;
}
.swiper {
  background-color: black;
}
.swiper-button-next {
  background-color: unset;
  border-radius: 0;
  height: 5em;
  width: 10em;
  right: calc(50% - 700px);
  color: aliceblue;
}
.swiper-button-prev {
  background-color: unset;
  border-radius: 0;
  height: 5em;
  width: 10em;
  left: calc(50% - 700px);
  color: aliceblue;
}
.swiper-button-next svg {
  fill: #fff;
  height: 2.2em;
  width: 2.2em;
}
.swiper-button-prev svg {
  fill: #fff;
  height: 2.2em;
  width: 2.2em;
}
path {
  width: 40;
}
/* .slide-img {
  height: 400px;
  max-width: 400px;
} */
@media screen and (min-width: 800px) {
  .slide-img {
    padding-top: 80px;
    height: 100%;
    max-width: 1200px;
  }
}
@media screen and (max-width: 1230px) {
  .slide-img {
    padding-top: 100px;
    height: 250px;
    max-width: 1100px;
  }
}
@media screen and (max-width: 1080px) {
  .slide-img {
    padding-top: 100px;
    height: 230px;
    max-width: 1000px;
  }
}
@media screen and (max-width: 990px) {
  .slide-img {
    padding-top: 100px;
    height: 220px;
    max-width: 900px;
  }
}
@media screen and (max-width: 910px) {
  .slide-img {
    padding-top: 100px;
    height: 220px;
    max-width: 800px;
  }
}
@media screen and (max-width: 825px) {
  .slide-img {
    padding-top: 100px;
    height: 220px;
    max-width: 700px;
  }
}
@media screen and (max-width: 730px) {
  .slide-img {
    padding-top: 100px;
    height: 200px;
    max-width: 600px;
  }
}
@media screen and (max-width: 625px) {
  .slide-img {
    padding-top: 100px;
    height: 190px;
    max-width: 500px;
  }
}
@media screen and (max-width: 530px) {
  .slide-img {
    padding-top: 100px;
    height: 180px;
    max-width: 400px;
  }
}
@media screen and (max-width: 470px) {
  .slide-img {
    padding-top: 100px;
    height: 180px;
    max-width: 390px;
  }
}
@media screen and (max-width: 450px) {
  .slide-img {
    padding-top: 100px;
    height: 180px;
    max-width: 380px;
  }
}
@media screen and (max-width: 440px) {
  .slide-img {
    padding-top: 100px;
    height: 180px;
    max-width: 370px;
  }
}
@media screen and (max-width: 420px) {
  .slide-img {
    padding-top: 100px;
    height: 175px;
    max-width: 360px;
  }
}
@media screen and (max-width: 410px) {
  .slide-img {
    padding-top: 100px;
    height: 170px;
    max-width: 350px;
  }
}
@media screen and (max-width: 400px) {
  .slide-img {
    padding-top: 100px;
    height: 165px;
    max-width: 340px;
  }
}
@media screen and (max-width: 390px) {
  .slide-img {
    padding-top: 100px;
    height: 160px;
    max-width: 330px;
  }
}
@media screen and (max-width: 380px) {
  .slide-img {
    padding-top: 100px;
    height: 155px;
    max-width: 320px;
  }
}
@media screen and (max-width: 370px) {
  .slide-img {
    padding-top: 100px;
    height: 150px;
    max-width: 310px;
  }
}
@media screen and (max-width: 360px) {
  .slide-img {
    padding-top: 100px;
    height: 145px;
    max-width: 300px;
  }
}
@media screen and (max-width: 350px) {
  .slide-img {
    padding-top: 100px;
    height: 140px;
    max-width: 290px;
  }
}
@media screen and (max-width: 340px) {
  .slide-img {
    padding-top: 100px;
    height: 135px;
    max-width: 280px;
  }
}
@media screen and (max-width: 330px) {
  .slide-img {
    padding-top: 100px;
    height: 130px;
    max-width: 270px;
  }
}
</style>
